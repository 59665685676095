import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import * as pbi from 'powerbi-client';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { ApiService } from '../../../../SGRE-shared/services/api.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Subject, map, takeUntil } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-sold-tovs-sales-chart',
  templateUrl: './sold-tovs-sales-chart.component.html',
  styleUrl: './sold-tovs-sales-chart.component.scss'
})
export class SoldTovsSalesChartComponent implements OnInit, OnDestroy {

  public role: string = '';
  reportClass = 'reportContainer';
  public report1 = false;
  public powerBiArray: any[] = [];
  report!: pbi.Embed;
  public componentData: any;
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: '',
    tokenType: models.TokenType.Embed,
    accessToken: '',
    settings: {
      panes: {
        filters: {
          visible: false
        },
        pageNavigation: {
          visible: false
        },
      },

    },
  }
  private unsubscribe$ = new Subject<void>();

  constructor(
    private storageService: StorageService,
    private globalService: GlobalService,
    private apiService: ApiService,
    public changeRef: ChangeDetectorRef,
    public component: CmsComponentData<any>
  ) { }

  ngOnInit() {
    this.getFromSessionStorage();
    if (this.role === 'SGRE Admin' || this.role === 'Sales Rep') {
      this.fetchPowerBiData();
    }
    this.component.data$.subscribe((data) => {
      return this.componentData = 'Sold To VS Sale';
    });
  }

  fetchPowerBiData() {
    this.globalService.loadingSubject.next(true);
    let cstmData = `chartNames=soldToSalesChart`
    this.apiService.getPowerBi(cstmData, {})
      .pipe(
        map((data: any) => {
          this.report1 = true;
          this.powerBiArray = data?.powerBIChart
          if (this.powerBiArray?.length) {
            this.reportConfig = {
              type: 'report',
              embedUrl: this.powerBiArray[0]?.embedUrl?.embedUrl,
              tokenType: models.TokenType.Embed,
              accessToken: this.powerBiArray[0]?.embedToken?.token,
              id: this.powerBiArray[0]?.embedUrl?.reportId,
              settings: {
                panes: {
                  filters: {
                    visible: false
                  },
                  pageNavigation: {
                    visible: false
                  },
                },

              },
            };
          }
          this.changeRef.detectChanges();
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: (data) => {
          this.globalService.loadingSubject.next(false);
        },
        error: (error) => this.globalService.loadingSubject.next(false)
      });
  }

  getFromSessionStorage() {
    let userRole: any = ''
    userRole = this.storageService.userRoles;

    if (userRole?.includes("SGRE Admin")) {
      this.role = 'SGRE Admin'
    }
    else if (userRole?.includes("Sales Rep")) {
      this.role = 'Sales Rep'
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
